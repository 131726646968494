import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { Spacer } from "@/components/Spacer";

const PostListByAuthorPage: React.FC<
  PageProps<Queries.PostListByAythorQuery>
> = ({ data }) => {
  const name = data.contentfulAuthor?.name;
  const slug = data.contentfulAuthor?.slug;
  const title = name + "の投稿一覧";
  const description = `${name}の投稿を一挙に見ていきましょう`;
  const url = "https://boctoz.com/author/" + slug;

  const seoProps: GatsbySeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      url,
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="prose max-w-full pt-12 pb-8">
        <h1 className="md:text-6xl">{title}</h1>
      </div>

      <Spacer size={20} />

      <ul className="flex list-none flex-wrap gap-4">
        {data.allContentfulPost.nodes.map((node) => (
          <>
            {node.ogImage?.gatsbyImageData && (
              <Link
                to={`/post/${node.slug}`}
                key={node.slug}
                className="group md:w-[calc(50%-0.5rem)]"
              >
                <li className="max-w-[440px] rounded shadow transition-shadow group-hover:shadow-lg">
                  <GatsbyImage
                    image={node.ogImage?.gatsbyImageData}
                    alt={""}
                    className="w-full rounded-t object-contain"
                  />
                  <h2 className="m-0 px-2 pt-3 font-bold">{node.title}</h2>
                  <p className="px-2 pt-1 pb-2 text-gray-400">
                    Published at {node.createdAt}
                  </p>
                </li>
              </Link>
            )}
          </>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query PostListByAythor($slug: String) {
    contentfulAuthor(slug: { eq: $slug }) {
      name
      slug
    }
    allContentfulPost(filter: { author: { slug: { eq: $slug } } }) {
      nodes {
        title
        slug
        createdAt(formatString: "YYYY-MM-DD")
        ogImage {
          gatsbyImageData(width: 440, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;

export default PostListByAuthorPage;
